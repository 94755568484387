.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.root h1 {
  margin-bottom: 1.6rem;
  font-size: 24px;
  text-align: center;
}

.btn-container {
  display: flex;
  justify-content: center;
}

.btn-primary {
  background-color: #1e293b;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  display: inline-block;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 10px;
}

.btn-primary:hover {
  background-color: #334155;
}

.user-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #f4f4f4;
  background-image: linear-gradient(to bottom right, #f4f4f4, #eaeaea);
  font-family: "Helvetica Neue", Arial, sans-serif;
}

.card {
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  padding: 40px;
  text-align: center;
  width: 80%;
  max-width: 500px;
  margin: 20px;
}

.profile-pic {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 20px;
  border: 3px solid #eaeaea;
}

.name {
  color: #333;
  margin: 10px 0;
  font-size: 32px;
  font-weight: 600;
}

.email {
  color: #666;
  font-size: 16px;
  margin: 5px 0;
}

.locale {
  color: #666;
  font-size: 14px;
  margin-top: 25px;
  letter-spacing: 1px;
  font-family: monospace;
}

.create-story-button {
  display: block;
  width: 100%;
  border: none;
  background-color: #7d4a7d;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  color: white;
}