.error-modal {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #ffdddd;
    border: 1px solid #ff5c5c;
    padding: 10px;
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.2);
    border-radius: 5px;
    z-index: 1000;
    width: 200px;
}

.error-modal p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    text-align: center;
    color: #ff0000;
}
