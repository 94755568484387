.App-content {
    padding: 20px;
    background-color: #ffffff;
}

.input-mode-tabs button {
    margin-right: 10px;
    padding: 5px 10px;
    cursor: pointer;
}

.input-mode-tabs .active {
    background-color: #d3d3d3;
}

.generate-script-button {
    margin-top: 10px;
    padding: 10px;
    cursor: pointer;
  }