.loading-information {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 1000;
    width: 200px;
}

.loading-information p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    text-align: center;
}

.loading-bar {
    position: relative;
    width: 100%;
    height: 5px;
    background-color: #f3f3f3;
    border-radius: 3px;
    margin-top: 10px;
}

.loading-progress {
    position: absolute;
    width: 0;
    height: 100%;
    background-color: #4caf50;
    border-radius: 3px;
    animation: loadingAnimation 2s infinite;
}

@keyframes loadingAnimation {
    0% {
        width: 0;
    }
    50% {
        width: 100%;
    }
    100% {
        width: 0;
    }
}
