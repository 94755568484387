.characters-view {
    padding: 20px 0;
    background-color: #f9f9f9;
}

.character-name {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
    outline: none;
    cursor: text;
    text-decoration: underline;
    padding: 2px;
    border: 1px dashed transparent;
    transition: border-color 0.3s;
}

.character-name:focus {
    border-color: #007bff;
    outline: none;
}

.three-container {
    width: 100%;
    height: 250px;
    object-fit: contain;
}