.App-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #e0e0e0;
}

.project-title {
    font-size: 2em;
    font-weight: bold;
    outline: none;
    cursor: text;
    text-decoration: underline;
    padding: 2px;
    border: 1px dashed transparent;
    transition: border-color 0.3s;
}

.project-title:focus {
    border-color: #007bff;
    outline: none;
}

.title-bar-actions {
    display: flex;
    align-items: center;
}

.title-bar-actions .ms-2 {
    margin-right: 0.5rem; /* Ensure there's space between the button and the badge */
}