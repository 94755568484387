.script-view {
    padding: 20px 0;
    background-color: #f9f9f9;
}

.scene-tabs {
    display: flex;
    margin-bottom: 10px;
}

.scene-tabs button {
    margin-right: 10px;
    padding: 5px 10px;
    cursor: pointer;
}

.scene-tabs .active {
    background-color: #d3d3d3;
}

.add-scene-button,
.parse-characters-button
.download-script-button {
    margin-left: 10px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 4px;
}

.scene-content {
    width: 100%;
    height: 300px;
    padding: 10px;
}