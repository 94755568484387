.camera-card {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.camera-preview-image {
    flex: 1;
    max-width: 33.33%;
    overflow: hidden;
}

.camera-preview-image .inner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.camera-card-content {
    flex: 2;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
}

.camera-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.camera-card-header .autohide {
    opacity: 0;
    transition: opacity 0.3s ease;
    margin-left: 1em;
}

.camera-card:hover .autohide {
    opacity: 1;
}

.autohide:hover {
    cursor: pointer;
}

.camera-card-body{
    margin-top: 1em;
}

.camera-card-body p {
    margin: 0.5em 0;
}
