html, body, #root {
    height: 100%;
    margin: 0;
}

.container-full-height {
    min-height: 90vh; /* Full viewport height */
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1; /* Allow the content to expand */
    overflow: auto; /* Enable scrolling if content overflows */
}

.project-card {
    text-align: center;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    min-width: 242px !important;
    padding: 0px !important;
}

.project-image {
    width: 100%;
    min-width: 242px;
    height: 150px;
    object-fit: cover;
}

.edit-button {
    position: absolute;
    top: 145px;
    left: 50%;
    transform: translate(-50%, -100%);
    width: 232px;
    padding: 0 !important;
    opacity: 0.9;
}

.project-card .card-body {
    text-align: left;
    padding: 10px;
    min-height: 7em; /* Adjust to fit title and description */
    max-height: 7em; /* Adjust to fit title and description */
    overflow: hidden; /* Hide overflow */
}

.projects-project-title {
    font-size: 9em; /* Make the title font size appropriate */
    white-space: nowrap; /* Ensure title is a single line */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Show ellipsis for overflow text */
    padding: 0;
    border: none;
}

.project-description {
    color: #605e5e;
    margin-top: 0;
    font-size: 0.8em; /* Make the text smaller */
    line-height: 1.5em; /* Set the line height */
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; /* Show ellipsis for overflow text */
}

.mb-2 {
    margin-bottom: 10px;
    min-width: 242px !important;
}

.project-col {
    min-width: 242px;
}