.App-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #f1f1f1;
  }
  
  .App-header-logo {
    display: flex;
    align-items: center;
  }
  
  .logo-circle {
    width: 20px;
    height: 20px;
    background-color: blue;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .App-header-nav span {
    margin-left: 20px;
    cursor: pointer;
  }
  