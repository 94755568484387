.advanced-form {
    display: flex;
    flex-direction: column;
}
  
.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group select {
    width: 100%;
    padding: 8px;
    margin-bottom: 5px;
}

.form-group input[type="range"] {
    width: 100%;
}