.App-tabs {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 10px;
    background-color: #e0e0e0;
  }
  
  .App-tabs button {
    padding: 10px;
    cursor: pointer;
  }
  
  .App-tabs .active {
    background-color: #d3d3d3;
  }
  